@mixin custom-card($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  .custom-card {
    border: 0 solid transparent;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;

    .mat-mdc-card-title {
      font-size: 16px;
      text-transform: uppercase;
    }

    .mat-mdc-card-subtitle {
      font-size: 14px;
    }

    .mat-mdc-card-content {
      font-size: 14px;
    }

    &.bordered {
      border: 1px solid map-get($foreground, dividers) !important;
    }
  }
}
