@use "@angular/material" as mat;

@mixin full-height-sidebar-drawer() {
  .sidenav .drawer-inner-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@mixin darker-sidebar() {
  .sidenav {
    background-color: map-get($eeule-palette, A700);
  }
}

@mixin darker-colors($theme) {
  .sidenav {
    color: map-get($dark-foreground, text) !important;

    .mat-mdc-list-item {
      &:hover {
        &:before {
          background-color: map-get($eeule-palette, 50);
        }

        .mat-mdc-list-item-unscoped-content {
          color: unset;
        }
      }

      .mat-mdc-list-item-unscoped-content {
        &:hover {
          color: unset;
        }
      }
    }

  }
}

@mixin navigation($theme) {
  .sidenav {
    .mat-mdc-list-item {
      &.is-active {
        .mat-mdc-list-item-unscoped-content {
          color: mat.get-theme-color($theme, primary, lighter)!important;
        }
        &:before {
          content: "";
          height: 100%;
          width: 2px;
          opacity: 1;
          background-color: mat.get-theme-color($theme, primary, lighter)!important;
        }
      }

      .mat-mdc-list-item-unscoped-content {
        color: map-get($dark-foreground, secondary-text);
      }
    }
  }
}

@mixin sidenav($theme) {
  $type: map-get($theme, type);
  @include navigation($theme);
  @include full-height-sidebar-drawer();
  @if ($type == default) {
    @include darker-sidebar();
    @include darker-colors($theme);
  }
}
