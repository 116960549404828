@use "@angular/material" as mat;

@mixin tabs($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  $type: map-get($theme, type);
  .mat-mdc-tab-label-container {
    border-bottom: 1px solid;
    border-bottom-color: map-get($foreground, divider);
    //border-bottom-color: if($type == dark, map-get($foreground, divider), #e0e2ec);
  }
  .default {
    .mat-mdc-tab-body-wrapper {
      height: 100%;
    }

    .mat-tab-label.mat-tab-label-active {
      background: transparent !important;
    }

    .mat-mdc-tab {
      &.mdc-tab--active {
        background-color: transparent !important;

        .mdc-tab__text-label {
          color: map-get($foreground, text);
        }
      }
    }
  }
}
