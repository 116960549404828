@use "@angular/material" as mat;
@import "theme";

@mixin button-shape() {
  button.rounded {
    border-radius: 25px;
  }
  button.square {
    border-radius: 0;
  }
  button.smaller-button {
    width: 32px;
    height: 32px;
    padding: 4px;

    .mat-mdc-button-touch-target {
      width: 32px;
      height: 32px;
    }

    &.mdc-icon-button {
      .mat-icon {
        font-size: 18px !important;
        width: 18px !important;
        height: 18px !important;
      }
    }
  }

  button.medium-button {
    width: 38px;
    height: 38px;
    padding: 7px;

    .mat-mdc-button-touch-target {
      width: 38px;
      height: 38px;
    }

    &.mdc-icon-button {
      .mat-icon {
        font-size: 20px !important;
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

@mixin font() {
  [mat-raised-button],
  [mat-flat-button],
  [mat-fab],
  [mat-mini-fab] {
    .mdc-button__label {
      font-weight: 400 !important
    }
  }
}

@mixin button-colors($theme) {
  $type: map-get($theme, type);
  [mat-raised-button],
  [mat-flat-button],
  [mat-fab],
  [mat-mini-fab] {
    &.primary {
      background-color: mat.get-theme-color($theme, primary, default);
      color: white;
    }

    &.accent {
      background-color: mat.get-theme-color($theme, accent, default);
      color: white;
    }

    &.accent-2 {
      background-color: map-get($highlights, accent-2);
      color: white;
    }

    &.accent-3 {
      background-color: map-get($highlights, accent-2);
      color: white;
    }

    &.accent-4 {
      background-color: map-get($highlights, accent-4);
      color: white;
    }

    &.accent-5 {
      background-color: map-get($highlights, accent-5);
      color: white;
    }

    &.success {
      background-color: map-get($highlights, success);
      color: white;
    }

    &.warn {
      background-color: map-get($highlights, warn);
      color: white;
    }

    &.error {
      background-color: map-get($highlights, error);
      color: white;
    }
  }
}

@mixin radio-button($theme) {
  $type: map-get($theme, type);
  @if ($type == dark) {
    .mat-mdc-radio.secondary {
      @include mat.radio-color($dark-secondary-config);
    }
  }
}

@mixin checkbox($theme) {
  $type: map-get($theme, type);
  @if ($type == dark) {
    .mat-mdc-checkbox.secondary {
      @include mat.checkbox-color($dark-secondary-config);
    }
  }
}

@mixin slide-toggle($theme) {
  $type: map-get($theme, type);
  @if ($type == dark) {
    .mat-mdc-slide-toggle.secondary {
      @include mat.slide-toggle-color($dark-secondary-config);
    }
  }
}

@mixin buttons($theme) {
  @include button-shape();
  @include font();
  @include radio-button($theme);
  @include checkbox($theme);
  @include slide-toggle($theme);
}
