@mixin filled-input-shape() {
  .mat-mdc-form-field.square {
    .mdc-text-field--filled {
      border-radius: 0 !important;
    }
  }
  .mat-mdc-form-field.rounded {
    .mdc-text-field--filled.rounded {
      border-radius: 25px !important;
    }
  }
}

@mixin filled-input-background($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  $background: map-get($color, background);
  .mat-mdc-form-field.reduced {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  .mat-mdc-form-field.smaller-shape {
    .mdc-text-field--filled {
      height: 52px;
    }
  }
  .mat-mdc-form-field.no-background {
    .mat-mdc-form-field-focus-overlay {
      background-color: unset;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: unset !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-line-ripple::before {
      border-bottom-color: map-get($foreground, disabled-text);
    }
  }
  .mat-mdc-form-field.custom-background {
    .mat-mdc-form-field-focus-overlay {
      background-color: map-get($background, secondary-background);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: map-get($background, secondary-background) !important;
    }
  }
  .mat-mdc-form-field.custom-background-alt {
    .mat-mdc-form-field-focus-overlay {
      background-color: map-get($background, tertiary-background);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: map-get($background, tertiary-background) !important;
    }
  }
  .mat-mdc-form-field.custom-background-alt2 {
    .mat-mdc-form-field-focus-overlay {
      background-color: map-get($background, quaternary-background);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: map-get($background, quaternary-background) !important;
    }
  }
  .mat-mdc-form-field.disabled-transparent {
    .mdc-text-field--filled.mdc-text-field--disabled {
      background-color: transparent !important;
    }
  }
}

@mixin outlined-input-shape() {
  .mat-mdc-form-field.rounded {
    border-radius: 25px;

    .mdc-text-field--outlined {
      .mdc-notched-outline__leading {
        border-top-left-radius: 25px !important;
        border-bottom-left-radius: 25px !important;
        width: 25px;
      }

      .mdc-notched-outline__trailing {
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important;
      }
    }
  }

  .mat-mdc-form-field.rounded.smaller-shape {
    .mat-mdc-form-field-bottom-align {
      display: none;
    }

    .mat-mdc-form-field-flex {
      height: 36px;
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      height: 36px;
      line-height: 36px;
      min-height: 0;
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
      transform: translateY(-18px) translateX(20px) !important;
    }
  }
}

@mixin font-style($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: map-get($foreground, secondary-text);
  }
  .mat-mdc-form-field.smaller-font, .mat-mdc-select {
    label, .mat-mdc-form-field-input-control {
      font-size: 14px !important;
    }

    .mat-icon {
      font-size: 22px !important;
      width: 22px !important;
      height: 22px !important;
    }
  }
}

@mixin select($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  .mat-mdc-form-field-type-mat-select {
    &.mat-form-field-disabled {
      .clear-selection .mat-icon {
        color: map-get($foreground, disabled-text);
      }
    }

    &.clearable {
      .mat-mdc-select-value {
        padding-right: 2.5rem;
      }
    }

    .clear-selection {
      position: absolute;
      right: 0;
      margin-right: 18px;
      margin-top: -12px;
    }
  }
}

@mixin form-field($theme) {
  @include font-style($theme);
  @include filled-input-shape();
  @include filled-input-background($theme);
  @include outlined-input-shape();
  @include select($theme);
}
