@mixin colors($theme) {
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);
  .mat-mdc-dialog-title {
    color: map-get($foreground, text) !important;
  }

  .mat-mdc-dialog-content {
    color: map-get($foreground, secondary-text) !important;
  }
}

@mixin spacing() {
  .mat-mdc-dialog-title {
    padding: 0 1rem .75rem;
  }

  .mat-mdc-dialog-content {
    padding: 0 1rem 1.25rem!important;
  }

  .mat-mdc-dialog-actions {
    padding: .5rem 1rem 1rem;
    justify-content: flex-end;
  }
}

@mixin dialog($theme) {
  @include colors($theme);
  @include spacing();
}
