@use "@angular/material" as mat;

@mixin typography($theme) {
  .page-header.mat-toolbar {
    font: mat.get-theme-typography($theme, body-1);
  }
}

@mixin page-header($theme) {
  @if mat.theme-has($theme, typography) {
    @include typography($theme);
  }
}
