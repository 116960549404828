@mixin background() {
  .mdc-snackbar.success > div {
    background-color: map-get($highlights, success);
  }
  .mdc-snackbar.warning > div {
    background-color: map-get($highlights, warn);
  }
  .mdc-snackbar.error > div {
    background-color: map-get($highlights, error);
  }
  .mdc-snackbar > div {
    color: white;
  }
}

@mixin label() {
  .mat-mdc-snack-bar-label {
    text-align: center;
    color: map-get($eeule-palette, 50) !important;
  }
}

@mixin snack-bar() {
  @include label();
  @include background();
}
