@use "@angular/material" as mat;

@mixin highlights($theme) {
  [mat-raised-button],
  [mat-flat-button],
  [mat-fab],
  [mat-mini-fab] {
    &.primary {
      background-color: mat.get-theme-color($theme, primary, default);
      color: white;
    }
    &.accent {
      background-color: mat.get-theme-color($theme, accent, default);
      color: white;
    }
    &.secondary {
      background-color: mat.get-theme-color($theme, primary, lighter);
      color: map-get($eeule-palette, 900);
    }
    &.accent-2 {
      background-color: map-get($highlights, accent-2);
      color: white;
    }
    &.accent-3 {
      background-color: map-get($highlights, accent-3);
      color: white;
    }
    &.accent-4 {
      background-color: map-get($highlights, accent-4);
      color: white;
    }
    &.dark {

    }
    &.success {
      background-color: map-get($highlights, success);
      color: white;
    }
    &.warn {
      background-color: map-get($highlights, warn);
      color: white;
    }
    &.error {
      background-color: map-get($highlights, error);
      color: white;
    }
  }

  .primary {
    color: mat.get-theme-color($theme, primary, default);
  }

  .accent {
    color: mat.get-theme-color($theme, accent, default);
  }

  .secondary {
    color: mat.get-theme-color($theme, primary, lighter)
  }

  .accent-2 {
    color: map-get($highlights, accent-2);
  }

  .accent-3 {
    color: map-get($highlights, accent-3);
  }

  .accent-4 {
    color: map-get($highlights, accent-4);
  }

  .success {
    color: map-get($highlights, success);
  }

  .warn {
    color: map-get($highlights, warn);
  }

  .error {
    color: map-get($highlights, error);
  }

  &.white-text {
    color: white;
  }

  &.light-text {
    color: map-get($eeule-palette, 50);
  }

  &.dark-text {
    color: map-get($eeule-palette, 900);
  }
}
