@use "@angular/material" as mat;
@import "theme";

@mixin icons($theme) {
  $type: map-get($theme, type);
  $color: map-get($theme, color);
  $foreground: map-get($color, foreground);

  .mat-icon, .custom-icon {
    &.icon {
      color: map-get($foreground, icon);
    }

    &.smaller-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }

    &.small-icon {
      width: 21px;
      height: 21px;
      font-size: 21px;
    }

    &.lighter {
      color: map-get($eeule-palette, A200);
    }

    &.custom-bg {
      //background-color: if($type == dark, mat.get-theme-color($theme, primary, default), map-get($eeule-palette, 400));
      //background-color: if($type == dark, mat.get-theme-color($theme, primary, default), mat.get-color-from-palette(mat.$teal-palette, 700));
      background-color: if($type == dark, mat.get-color-from-palette(mat.$cyan-palette, 400), mat.get-color-from-palette(mat.$teal-palette, 700));
      color: if($type == dark, map-get($eeule-palette, 700), map-get($eeule-palette, 50));
    }

    &.primary-bg {
      background-color: mat.get-theme-color($theme, primary, default);
      color: if($type == dark, map-get($eeule-palette, 800), map-get($eeule-palette, 50));
    }

    &.secondary-bg {
      background-color: if($type == dark, mat.get-theme-color($theme, primary, default), map-get($highlights, secondary));
      color: if($type == dark, map-get($eeule-palette, 800), black);
      //color: if($type == dark, map-get($eeule-palette, 800), map-get($eeule-palette, 50));
    }

    &.accent-bg {
      background-color: mat.get-theme-color($theme, accent, default);
      color: if($type == dark, map-get($eeule-palette, 800), map-get($eeule-palette, 50));
    }

    &.accent-2-bg {
      background-color: map-get($highlights, accent-2);
      color: white;
    }

    &.accent-3-bg {
      background-color: map-get($highlights, accent-3);
      color: white;
    }

    &.accent-4-bg {
      background-color: map-get($highlights, accent-4);
      color: white;
    }

    &.accent-5-bg {
      background-color: map-get($highlights, accent-5);
      color: white;
    }

    &.rounded-bg {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      display: flex;
      justify-content: center;
    }

    &.leaf-bg {
      width: 24px;
      height: 24px;
      border-radius: 8px 4px 8px 4px;
      display: flex;
      justify-content: center;
    }

  }
}
