@use "@angular/material" as mat;
@import "variables";


/* --- default --- */
$default-config: mat.define-light-theme((
  color: (primary: $primary-default, accent: $accent-default),
  typography: mat.define-typography-config(),
  density: 0
));

$default-colors: map-get($default-config, color);
$default-colors: map-merge(
    $default-colors,
    (
      background: $default-background,
      foreground: $default-foreground,
    )
);

$default-theme: map-merge($default-config, (color: $default-colors, type: 'default'));

/* --- dark --- */
$dark-config: mat.define-dark-theme((
  color: (primary: $primary-dark, accent: $accent-dark),
  typography: mat.define-typography-config()
));

$dark-colors: map-get($dark-config, color);
$dark-colors: map-merge(
    $dark-colors,
    (
      background: $dark-background,
      foreground: $dark-foreground,
    )
);

$dark-theme: map-merge($dark-config, (color: $dark-colors, type: 'dark'));

/* --- dark secondary --- */
$dark-secondary-config: mat.define-dark-theme((
  color: (
    primary: $primary-dark,
    accent: mat.define-palette(mat.$deep-orange-palette, 400)
  )
));

$dark-config-colors: map-get($dark-secondary-config, color);
$dark-config-colors: map-merge(
    $dark-config-colors,
    (
      background: $dark-background,
      foreground: $dark-foreground,
    )
);

$dark-secondary-theme: map-merge($dark-secondary-config, (color: $dark-config-colors, type: 'dark'));

