@use "@angular/material" as mat;

@mixin menu($theme) {
  $type: map-get($theme, type);
  @if ($type == dark) {
    .mat-mdc-menu-panel {
      background-color: map-get($eeule-palette, 500);
    }
  }
  .mat-mdc-menu-panel.sidebar {
    @include mat.menu-color($dark-theme);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

@mixin overlay($theme) {
  @include menu($theme);
}
