@use "@angular/material" as mat;
@import "styles/theme";
@import "styles/page-header-theme";
@import "styles/logo-theme";
@import "styles/sidebar-theme";
@import "styles/card-theme";
@import "styles/highlights-theme";
@import "styles/form-field-theme";
@import "styles/buttons-theme";
@import "styles/icons-theme";
@import "styles/snack-bar-theme";
@import "styles/overlay-theme";
@import "styles/dialog-theme";
@import "styles/contex-navigation-theme";
@import "styles/scrollbar-theme";
@import "styles/drag-theme";
@import "styles/tabs-theme";
@import "styles/quill-theme";

@include mat.core();

@include mat.core-theme($default-theme);
// FIXME: use dedicated material component themes instead of all-component-themes
@include mat.all-component-themes($default-theme);
@include snack-bar();

/* default theme */
body:has(.default-theme) {
  @include page-header($default-theme);
  @include logo($default-theme);
  @include sidenav($default-theme);
  @include custom-card($default-theme);
  @include highlights($default-theme);
  @include overlay($default-theme);
  @include buttons($default-theme);
  @include form-field($default-theme);
  @include dialog($default-theme);
  @include scrollbar($default-theme);
  @include drag($default-theme);
  @include context-navigation($default-theme);
  @include icons($default-theme);
  @include tabs($default-theme);
  @include quill($default-theme);

  .contrast {
    color: black;
  }

  .primary-text {
    color: map-get($default-foreground, text);
  }

  .secondary-text {
    color: map-get($default-foreground, secondary-text);
  }

  .disabled-text {
    color: map-get($default-foreground, disabled-text);
  }

  .primary-background {
    background: map-get($default-background, background);
  }

  .secondary-background {
    background-color: map-get($default-background, secondary-background);
  }

  .tertiary-background {
    background-color: map-get($default-background, tertiary-background);
  }

  .quaternary-background {
    background-color: map-get($default-background, quaternary-background);
  }

  .overlay-background {
    background-color: white;
  }

  .shadow-1 {
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  }

  .default-border {
    border: 1px solid map-get($default-foreground, divider);
  }

  .secondary-border {
    border: 1px solid map-get($default-background, background);
  }

  .default-border-color {
    border-color: map-get($default-foreground, divider);
  }

  .secondary-border-color {
    border-color: map-get($default-background, background);
  }

  .v-divider {
    width: 1px;
    background: map-get($default-foreground, divider);
  }

  .v-divider-2 {
    width: 1px;
    background: map-get($default-background, background);
  }

  a {
    color: mat.get-theme-color($default-theme, primary, lighter)
  }
}

/* default mobile login theme adjustments */
.default-theme:has(.login.handset) {
  background-color: white;
}


/* dark theme */
body:has(.dark-theme) {
  @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
  @include mat.sidenav-color($dark-theme);
  @include page-header($dark-theme);
  @include logo($dark-theme);
  @include sidenav($dark-theme);
  @include custom-card($dark-theme);
  @include highlights($dark-theme);
  @include overlay($dark-theme);
  @include buttons($dark-theme);
  @include form-field($dark-theme);
  @include dialog($dark-theme);
  @include scrollbar($dark-theme);
  @include drag($dark-theme);
  @include context-navigation($dark-theme);
  @include icons($dark-theme);
  @include tabs($dark-theme);
  @include quill($dark-theme);

  .contrast {
    color: white;
  }

  .primary-text {
    color: map-get($dark-foreground, text);
  }

  .secondary-text {
    color: map-get($dark-foreground, secondary-text);
  }

  .disabled-text {
    color: map-get($dark-foreground, disabled-text);
  }

  .primary-background {
    background: map-get($dark-background, background);
  }

  .secondary-background {
    background-color: map-get($dark-background, secondary-background);
  }

  .tertiary-background {
    background-color: map-get($dark-background, tertiary-background);
  }

  .quaternary-background {
    background-color: map-get($dark-background, quaternary-background);
  }

  .overlay-background {
    background-color: map-get($eeule-palette, 700);
  }

  .shadow-1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  a {
    color: mat.get-theme-color($dark-theme, primary);
  }

  .default-border {
    border: 1px solid map-get($dark-foreground, divider);
  }

  .secondary-border {
    border: 1px solid map-get($dark-background, background);
  }

  .default-border-color {
    border-color: map-get($dark-foreground, divider);
  }

  .secondary-border-color {
    border-color: map-get($dark-background, background);
  }

  .v-divider {
    width: 1px;
    height: 80%;
    background: map-get($dark-foreground, divider);
  }

  .v-divider-2 {
    width: 1px;
    background: map-get($dark-background, background);
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font: mat.get-theme-typography($default-theme, body-1);
  letter-spacing: mat.get-theme-typography($default-theme, body-1, letter-spacing);
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* --- layout --- */
.transparent {
  background-color: transparent !important;
}

.hidden {
  display: none !important;
}

.square {
  border-radius: 0 !important;
}

.semi-round {
  border-radius: 15px !important;
}

.tertiary-text {
  color: map-get($eeule-palette, 300);
}

.no-shadow {
  box-shadow: unset !important;

  &:hover, &:focus, &:active {
    box-shadow: unset !important;
  }
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.45) 0 25px 20px -20px !important;
}

.h-auto {
  height: auto;
}

.h-100-percent {
  height: 100%;
}

.w-100-percent {
  width: 100%;
}
.w-30-percent {
  width: 30%;
}
.w-20-percent {
  width: 20%;
}

.square {
  border-radius: 0 !important;
}

.small-radius {
  border-radius: 4px;
}

.default-radius {
  border-radius: 5px;
}

.semi-round {
  border-radius: 15px !important;
}

.default-title {
  font-weight: 500;
}

.default-sub-title {
  font-size: .9em;
  font-weight: 500;
}

.loading-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollable {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* font */
.slim {
  font-weight: 400 !important;
}

/* flexbox */
.flexbox {
  display: flex !important;

  &.hidden {
    display: none !important;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start!important;
  }

  &.align-end {
    align-items: flex-end!important;
  }

  &.justify-start {
    justify-content: flex-start!important;
  }

  &.justify-end {
    justify-content: flex-end!important;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-spaced {
    justify-content: space-between;
  }

  &.centered {
    align-items: center;
    justify-content: center;
  }

  &.xl-gap {
    gap: 4rem;
  }

  &.large-gap {
    gap: 3rem
  }

  &.larger-gap {
    gap: 2rem
  }

  &.default-gap {
    gap: 1.5rem
  }

  &.smaller-gap {
    gap: 1rem;
  }

  &.semi-small-gap {
    gap: .75rem
  }

  &.small-gap {
    gap: .5rem
  }

  &.xs-gap {
    gap: .25rem;
  }
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/* grid */
.grid {
  display: grid;

  .left {
    grid-area: left;
  }

  .middle {
    grid-area: middle;
  }

  .right {
    grid-area: right;
  }

  .full {
    grid-area: full;
  }
}

/* material */
.filled-text-field-background {
  background-color: var(--mdc-filled-text-field-container-color);
}



