@mixin logo($theme) {
  $type: map-get($theme, type);
    @if ($type == dark) {
      .eeule-logo .logo {
        filter: invert(0.25) brightness(1.5) saturate(12) hue-rotate(328deg);
      }
      .eeule-logo .logo-label {
        filter: invert(0.25) brightness(1.5) saturate(12) hue-rotate(328deg);
      }
    }
}
