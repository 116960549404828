@mixin scrollbar($theme) {
  $type: map-get($theme, type);
  .custom-scrollbar, [cdkscrollable] {
    overflow-y: auto;
    @include scrollbar-appearance($type);
  }
}

@mixin scrollbar-appearance($type) {
  &.sb-spacing {
    padding-right: 1px;
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: if($type == dark, rgba(255, 255, 255, 0.005), rgba(0, 0, 0, 0.01));
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: if($type == dark, map-get($eeule-palette, A400), #7c869b);
    //background: if($type == dark, rgba(155, 155, 155, 0.5), rgba(0, 0, 0, 0.5));
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: if($type == dark, #5f6578, #6b758a);
    //background: if($type == dark, rgba(155, 155, 155, 0.6), rgba(0, 0, 0, 0.6));
  }
}
